import restApi from '@/services/api/restApi'

// This function returns the config for a specific restaurant. If the
// application is running in development mode, the resto_id will be
// overridden with the ID of the restaurant specified in the .env file.
export const getRestoWebConfigFn = async () => {
  let resto_config_url = '/web/resto/web_config'
  if (
    import.meta.env.DEV &&
    import.meta.env.MODE === 'development' &&
    import.meta.env.VITE_DEV_RESTO_ID
  ) {
    resto_config_url += '?rstm_resto_id=' + import.meta.env.VITE_DEV_RESTO_ID
  } else if (import.meta.env.VITE_MOBILE_APP && import.meta.env.VITE_MOBILE_APP_RESTO_ID) {
    resto_config_url += '?rstm_resto_id=' + import.meta.env.VITE_MOBILE_APP_RESTO_ID
  }
  const response = await restApi.get(resto_config_url)
  return response.data
}

export const getCustomerInfoFn = async () => {
  const response = await restApi.get(`/web/customer/info`)
  return response.data
}

export const customerLoginFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/login`, form_data)
  return response.data
}

export const isCustomerEmailAvailableFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/is_email_available`, form_data)
  return response.data
}

export const customerLogoutFn = async () => {
  const response = await restApi.post(`/web/customer/logout`)
  return response.data
}

export const getCartInfoFn = async (resto_id) => {
  const response = await restApi.get(`/web/customer/cart/info?resto_id=${resto_id}`)
  return response.data
}

export const getRestoMenuFn = async (resto_id) => {
  const response = await restApi.get(`/web/resto/menu/${resto_id}`)
  return response.data
}

export const getRestoInfoFn = async (resto_id) => {
  const response = await restApi.get(`/web/resto/info/${resto_id}`)
  return response.data
}

export const getOrderOptionFn = async (resto_id, router = null) => {
  const response = await restApi.get(`/web/customer/order/get_options?resto_id=${resto_id}`)
  handleResponse401Error(response, router)
  return response.data
}

export const getRestoPaymentSettingFn = async (resto_id, router = null) => {
  const response = await restApi.get(`/web/resto/payment_settings/${resto_id}`)
  handleResponse401Error(response, router)
  return response.data
}

export const getOrderStatusFn = async (order_id, resto_id) => {
  const response = await restApi.get(`/web/customer/order/${order_id}?resto_id=${resto_id}`)
  return response.data
}

export const getOrdersFn = async (current_page, resto_id) => {
  const response = await restApi.get(
    `/web/customer/orders?resto_id=${resto_id}&page=${current_page}`
  )
  return response.data
}

export const canRestoServePostCodeFn = async (post_code, resto_id) => {
  const response = await restApi.post(
    `/web/resto/can_serve_post_code/${resto_id}?post_code=${post_code}`
  )
  return response.data
}

export const updateEmailFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/settings/change_email`, form_data)
  return response.data
}

export const updateCustomerInfoFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/settings/update_info`, form_data)
  return response.data
}

export const forgotPasswordFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/forgot_password`, form_data)
  return response.data
}
export const verifyTokenFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/password/verify_token`, form_data)
  return response.data
}
export const resetPasswordFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/password/reset`, form_data)
  return response.data
}

export const registerNewUserFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/register`, form_data)
  return response.data
}

export const changePasswordFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/settings/change_password`, form_data)
  return response.data
}

export const updateOrderTypeFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/update_order_type`, form_data)
  return response.data
}

export const updatPaymentTypeFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/update_payment_type`, form_data)
  return response.data
}

export const addSimpleItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const removeSimpleItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const addComplexItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const removeComplexItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const addMealItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const removeMealItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const removeAllItemFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const addVoucherCodeFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const removeVoucherCodeFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/action`, form_data)
  return response.data
}

export const updateMobileCartFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/cart/update_is_mobile `, form_data)
  return response.data
}

export const setOrderOptionsFn = async (form_data, router = null) => {
  const response = await restApi.post(`/web/customer/order/set_options`, form_data)
  handleResponse401Error(response, router)
  return response.data
}

export const reorderFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/reorder`, form_data)
  return response.data
}

export const confirmDetailsFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/order/confirm_details`, form_data)
  return response.data
}

export const placeCashOrderSCAFn = async (form_data) => {
  const response = await restApi.post(`/web/customer/order/place_order_sca`, form_data)
  return response.data
}

export const createOrderPIFn = async (resto_id) => {
  const response = await restApi.post(`/web/customer/order/create_pi?resto_id=${resto_id}`)
  return response.data
}

export const placeCardOrderSCAFn = async (resto_id, payment_method_id) => {
  const response = await restApi.post(
    `/web/customer/order/place_order_sca?resto_id=${resto_id}&payment_method_id=${payment_method_id}`
  )
  return response.data
}

export const confirmOrderPIFn = async (resto_id, order_id) => {
  const response = await restApi.post(
    `/web/customer/order/${order_id}/confirm_pi?resto_id=${resto_id}`
  )
  return response.data
}

export const customerReservationFn = async (resto_id, form_data) => {
  const response = await restApi.post(`/web/resto/reservation/${resto_id}`, form_data)
  return response.data
}

export const contactFn = async (resto_id, form_data) => {
  const response = await restApi.post(`/web/resto/contact/${resto_id}`, form_data)
  return response.data
}

export const stopSmsFn = async (resto_id, form_data) => {
  const response = await restApi.post(`/web/resto/${resto_id}/stop_sms`, form_data)
  return response.data
}

export const clientForgotPasswordFn = async (form_data) => {
  const response = await restApi.post(`/web/client/forgot_password`, form_data)
  return response.data
}
export const clientVerifyTokenFn = async (form_data) => {
  const response = await restApi.post(`/web/client/password/verify_token`, form_data)
  return response.data
}
export const clientResetPasswordFn = async (form_data) => {
  const response = await restApi.post(`/web/client/password/reset`, form_data)
  return response.data
}

const handleResponse401Error = (response, router) => {
  if (router && response.data?.status === 401 && router.currentRoute.value.name !== 'login') {
    router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } })
  }
}
