<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { getOrdersFn } from '@/services/api/restApiCalls'
import {
  ShoppingBagIcon,
  EyeIcon,
  BanknotesIcon,
  CreditCardIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/vue/20/solid'

const restoStore = useRestoGLobalStore()
const router = useRouter()
const ordersData = ref([])
const currentPage = ref(1)
const perPage = ref(10)
const total = ref(0)
const lastPage = ref(0)

const handleGetOrder = async () => {
  const orders_data = await getOrdersFn(currentPage.value, restoStore?.RESTO_ID)
  ordersData.value = orders_data?.orders
  currentPage.value = parseInt(orders_data?.current_page)
  perPage.value = orders_data?.records_per_page
  total.value = orders_data?.total_records
  lastPage.value = orders_data?.last_page
}
const handlePageClick = async (page) => {
  if (page < 1 || page > lastPage.value) return
  currentPage.value = page
  handleGetOrder()
}
onMounted(() => {
  handleGetOrder()
})
</script>
<template>
  <AppLayout>
    <div class="container mx-auto">
      <!-- <div class="sm:flex sm:items-center mb-2">
        <div class="sm:flex-auto">
          <h1
            class="text-xl md:text-2xl font-bold text-gray-900 dark:text-white text-center md:mb-6 mb-4"
          >
            My Orders
          </h1>
        </div>
      </div> -->
      <div class="flow-root mx-2">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table
              class="min-w-full divide-y divide-gray-300 bg-white border border-gray-200 rounded-sm"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 sm:pl-2 pl-1 md:pr-3 sm:pr-2 pr-1 text-left sm:text-base text-sm font-semibold text-gray-900"
                  >
                    Order Id
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-left sm:text-base text-sm font-semibold text-gray-900 hidden md:block"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-left sm:text-base text-sm font-semibold text-gray-900"
                  >
                    Order Type
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-left sm:text-base text-sm font-semibold text-gray-900"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-left sm:text-base text-sm font-semibold text-gray-900 hidden md:block"
                  >
                    Payment Type
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-left sm:text-base text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 md:px-3 sm:px-2 px-1 text-right sm:text-base text-sm font-semibold text-gray-900"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="order in ordersData"
                  :key="order.order_id"
                  @click="
                    router.push({
                      name: 'view_order',
                      params: { order_id: `${order.order_id}` }
                    })
                  "
                >
                  <td
                    class="whitespace-nowrap py-3 sm:pl-2 pl-1 md:pr-3 sm:pr-2 pr-1 sm:text-base text-sm md:font-semibold text-indigo-500 cursor-pointer"
                    @click="
                      router.push({
                        name: 'view_order',
                        params: { order_id: `${order.order_id}` }
                      })
                    "
                  >
                    {{ order.order_id }}
                  </td>
                  <td
                    class="whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 text-sm text-gray-900 hidden md:block"
                  >
                    {{ order.creation_datetime }}
                  </td>
                  <td
                    class="whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 text-sm text-gray-900 capitalize sm:font-medium font-normal"
                  >
                    <span
                      class="flex items-center sm:px-4 px-2 py-1 text-white rounded-full text-sm w-fit"
                      :class="[order?.order_type == 'delivery' ? 'bg-green-600' : 'bg-violet-600']"
                    >
                      <span v-if="order.order_type == 'collection'" class="sm:mr-1.5 mr-1">
                        <ShoppingBagIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                      </span>
                      <span v-if="order.order_type == 'delivery'" class="sm:mr-1.5 mr-1">
                        <svg
                          class="h-4 w-4 text-white fill-current"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                          ></path>
                        </svg>
                      </span>
                      {{ order.order_type }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 text-sm text-gray-900">
                    £{{ order.amount }}
                  </td>
                  <td
                    class="whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 text-sm text-gray-900 capitalize font-medium hidden md:block"
                  >
                    <span
                      class="flex items-center sm:px-4 px-2 py-1 text-white rounded-full text-sm w-fit"
                      :class="[order?.payment_type == 'cash' ? 'bg-yellow-600' : 'bg-purple-600']"
                    >
                      <span v-if="order.payment_type == 'cash'" class="sm:mr-1.5 mr-1">
                        <BanknotesIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                      </span>
                      <span v-if="order.payment_type == 'card'" class="sm:mr-1.5 mr-1">
                        <CreditCardIcon class="h-4 w-4 text-white mx-auto" aria-hidden="true" />
                      </span>
                      {{ order.payment_type }}
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 sm:text-sm text-xs text-gray-900"
                    :class="{
                      'text-red-600':
                        order.order_status == 'AUTHORIZED' ||
                        order.order_status == 'CANCELLED' ||
                        order.order_status == 'FAILURE',
                      'text-green-600': order.order_status == 'SUCCESS'
                    }"
                  >
                    {{ order.order_status }}
                  </td>
                  <td
                    class="relative whitespace-nowrap py-3 md:px-3 sm:px-2 px-1 text-right text-sm font-medium"
                  >
                    <button
                      class="w-full text-indigo-600 hover:text-indigo-900 flex items-center justify-end"
                      @click="
                        $router.push({
                          name: 'view_order',
                          params: { order_id: `${order.order_id}` }
                        })
                      "
                    >
                      <EyeIcon class="h-5 w-5 text-indigo-500 mr-1" aria-hidden="true" />
                      <span class="hidden sm:inline-block">Show</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mx-2"
      >
        <div class="flex flex-1 items-center justify-end">
          <div>
            <nav
              class="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                class="relative inline-flex items-center rounded-l-md sm:px-2 px-1 sm:py-2 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200 disabled:text-gray-400"
                @click="handlePageClick(currentPage - 1)"
                :disabled="currentPage == 1"
              >
                <span class="sr-only">Previous</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
              <template v-for="(page, index) in lastPage" :key="index">
                <button
                  class="relative inline-flex items-center sm:px-4 px-3 sm:py-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300"
                  :class="[
                    currentPage == index + 1
                      ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      : ''
                  ]"
                  @click="handlePageClick(index + 1)"
                >
                  {{ index + 1 }}
                </button>
              </template>

              <button
                class="relative inline-flex items-center rounded-r-md sm:px-2 px-1 sm:py-2 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-200 disabled:text-gray-400"
                @click="handlePageClick(currentPage + 1)"
                :disabled="currentPage == lastPage"
              >
                <span class="sr-only">Next</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
